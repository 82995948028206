import Head from 'next/head';
import Home from 'src/views/Marketing/Home';

export default function Example() {
  return (
    <>
      <Head>
        <title>OneShop: How resellers go pro</title>
        <meta
          name='description'
          content='Sell on more sites than you could alone. One simple app with every tool you need to level up. Designed for online sellers, just like you.'
        />
        <link rel='canonical' href='https://tools.oneshop.com/' />
        <meta property='og:url' content='https://tools.oneshop.com/' />
        <meta property='og:title' content='How resellers go pro | OneShop' />
        <meta
          property='og:description'
          content='Sell on more sites than you could alone. One simple app with every tool you need to level up. Designed for online sellers, just like you.'
        />
        <meta key='og-image' property='og:image' content='https://tools.oneshop.com/static/images/og-image.png?v=4' />
        <meta name='facebook-domain-verification' content='7uekn82ioz3pvtjswxsm0affj376yd' />
      </Head>
      <Home />
    </>
  );
}
